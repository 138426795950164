.link {
  border-bottom: 0.05em solid;
  border-color: rgba(68, 65, 58, 0.4);
  opacity: 0.75;
  position: relative;
}

.link-highlight {
  border-radius: 1px;
  background: rgba(165, 196, 231, 255);
}
