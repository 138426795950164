@value red-500 from "components/colours.module.css";

.loader-wrap,
.error-wrap,
.no-data-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  white-space: pre-wrap;
  text-align: center;
}

.loader-wrap {
  margin: auto;
}

.error-wrap {
  color: red-500;
}
