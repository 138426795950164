.h1 {
  font-size: 1.75rem;
  font-variation-settings: 'wght' 600;
  line-height: 3rem;
  width: 100%;
  padding-top: 0.75rem;
  padding-bottom: 4px;
}

.empty-h1:after {
  color: #b2b2b2;
  content: 'Heading 1';
  display: block;
  top: 0.75rem;
  position: absolute;
}

.empty-h1:hover {
  cursor: text;
}

.h2 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-variation-settings: 'wght' 600;
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 1px;
}

.empty-h2:after {
  color: #b2b2b2;
  content: 'Heading 2';
  display: block;
  top: 0.5rem;
  position: absolute;
}

.empty-h2:hover {
  cursor: text;
}

.list {
  font-size: 1rem;
  width: 100%;
  position: relative;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.list > span {
  line-height: 1.5rem;
}

.ordered-list {
  width: 100%;
}

.ordered-list:before {
  content: var(--listprefix);
  pointer-events: none;
  user-select: none;
}

.bulleted-list-solid-dot {
  width: 100%;
}

.bulleted-list-solid-dot:before {
  content: '•  ';
}

.bulleted-list-solid-square {
  width: 100%;
}

.bulleted-list-solid-square:before {
  content: '\25AA   ';
}

.bulleted-list-empty-square {
  width: 100%;
}

.bulleted-list-empty-square:before {
  content: '\25AB   ';
}

.bulleted-list-empty-dot {
  width: 100%;
}

.bulleted-list-empty-dot:before {
  content: '\25E6   ';
}

.empty-bulleted-list:after {
  color: #b2b2b2;
  content: 'List';
  display: block;
  top: 0.28rem;
  left: 1.2rem;
  position: absolute;
}

.empty-bulleted-list:hover {
  cursor: text;
}

.empty-ordered-list:after {
  color: #b2b2b2;
  content: 'List';
  display: block;
  top: 0.28rem;
  left: calc(var(--listprefix-length) * 0.4rem);
  position: absolute;
}

.empty-ordered-list:hover {
  cursor: text;
}

.paragraph {
  font-size: 1rem;
  width: 100%;
  position: relative;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.paragraph > span {
  line-height: 1.5rem;
}

.empty-paragraph:after {
  color: #b2b2b2;
  content: var(--blockPlaceholder);
  pointer-events: none;
  user-select: none;
  display: block;
  top: 0.25rem;
  position: absolute;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.empty-paragraph:hover {
  cursor: text;
}
