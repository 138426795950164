@value blue-400 from "components/colours.module.css";
@value grey-200 from "components/colours.module.css";

.container {
  width: 100%;
  position: relative;
  user-select: none;
}

.container.selected {
  outline: 2px solid blue-400;
}

.container .text {
  display: none;
}

.fieldBlockConatainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(calc(33.333% - 1px), 1fr));
  grid-template-rows: auto;
  grid-gap: 1px;
  background-color: grey-200;
  padding: 1px 0;
}

.actionBtns {
  position: absolute;
  right: 4px;
  top: 4px;
}

.moreActionBtn {
  width: auto;
  justify-content: center !important;
  background-color: rgba(0, 0, 0, 0.6) !important;
  font-size: 0.875rem;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  border-radius: 3px !important;
  margin-left: 2px !important;
}

.moreActionBtn:hover {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
