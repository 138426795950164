@value grey-600 from "components/colours.module.css";
.wrap .topRow {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.375rem 1rem;
  min-height: 2.25rem;
}
.wrap .topRow .timeWrap {
  color: grey-600;
  font-size: 0.75rem;
  flex: 1;
}

.wrap:not(.isFirst) .actions {
  opacity: 0;
  height: 1.5rem;
}

.wrap:not(.isFirst):hover .actions {
  opacity: 1;
}

.wrap.popoverShow .actions {
  opacity: 1 !important;
}

.wrap .content {
  padding-left: 2.25rem;
  padding-bottom: 0.25rem;
  padding-right: 1rem;
}

.menuBtn {
  width: 100%;
  justify-content: flex-start !important;
  height: 2.5rem;
  padding: 0 0.5rem !important;
}
