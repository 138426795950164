@value neutrals-gray-alt from "components/colours.module.css";
@value neutrals-dark from "components/colours.module.css";
@value grey-500 from "components/colours.module.css";

.btn {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
  font-size: 0.875rem !important;
}

.btn .icon {
  width: 1.25rem;
  min-width: 1.25rem;
  height: 1.25rem;
  color: neutrals-dark;
}

.infoItem {
  padding-bottom: 1rem;
}

.infoItem .infoItemTitle {
  color: grey-500;
  font-size: 0.875rem;
  font-variation-settings: 'wght' 600;
}

.infoItem .infoItemValue {
  color: neutrals-dark;
  font-size: 0.875rem;
}
