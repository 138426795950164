@value grey-100 from "components/colours.module.css";
@value grey-200 from "components/colours.module.css";
@value blue-500 from "components/colours.module.css";
@value blue-600 from "components/colours.module.css";
@value slate-400 from "components/colours.module.css";

.nav-bar {
  justify-content: space-between;
  width: 2.8rem;
  padding: 1rem 0;
  background-color: white;
  border-right: 1px solid grey-200;
  z-index: 3;
}

.logo {
  width: 2rem;
  height: 2rem;
}

.new-note-button {
  color: blue-600;
  stroke: white;
}

.new-note-button:hover {
  color: blue-500;
}

.new-note-button:disabled {
  cursor: not-allowed;
}

.nav-top {
  width: 100%;
}

.nav-items-top {
  margin-top: 2.5rem;
}

.nav-items-bottom {
  width: 100%;
  margin-top: 5rem;
  gap: 2rem;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
}

.icon {
  width: 100%;
  height: 100%;
  padding: 0.375rem;
}

.setting-item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
}

.setting-item:hover {
  background-color: grey-100;
}

.setting-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.children-container {
  width: 1px;
  height: 100%;
  z-index: 0;
  flex: 1;
}

.link {
  width: 2rem;
  height: 2rem;
}

.link-btn {
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  color: slate-400;
}

.link-btn:hover {
  background-color: grey-200;
}
