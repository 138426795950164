@value neutrals-white from "components/colours.module.css";

.countDownModal {
  background: linear-gradient(#e66465, #9198e5);
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
}

.countDownModal .countDownModalContent {
  width: 100%;
  height: 100%;
}

/* hide modal header */
.countDownModal .countDownModalContent > div:first-child {
  display: none;
}

.countDownModal .countDownModalContent .countDownModalBody {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0;
}

.countDownWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.countDownWrap span.countDownNum {
  color: neutrals-white;
  font-size: 3rem;
  line-height: 5rem;
  font-weight: 700;
  font-variation-settings: 'wght' 700;
  pointer-events: none;
}

.countDownWrap span.countDownNum:global(.big) {
  font-size: 6rem;
  opacity: 0.3;
  transition: font-size linear 1s, opacity linear 1s;
}

.countDownWrap button {
  color: neutrals-white;
  font-size: 1rem;
  font-weight: 400;
  padding: 1rem;
}
