.caret {
  width: 0.125rem;
  position: absolute;
}

.caret-label {
  position: absolute;
  top: 0;
  transform: translateY(-100%);
  font-size: 0.75rem;
  line-height: 1rem;
  color: white;
  padding: 0.125rem 0.3rem;
  border-radius: 0.25rem;
  white-space: nowrap;
}
