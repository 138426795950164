@value neutrals-stroke from "components/colours.module.css";
@value neutrals-stroke-alt from "components/colours.module.css";
@value neutrals-stroke-fields from "components/colours.module.css";
@value primary-primary-1 from "components/colours.module.css";
@value neutrals-gray-alt from "components/colours.module.css";
@value red-500 from "components/colours.module.css";
@value backgrounds-disabled from "components/colours.module.css";

.pickerBtn {
  width: 100%;
  border-radius: 0.25rem !important;
  padding: 0 !important;
  font-size: 0.875rem !important;
  color: neutrals-dark;
  font-weight: normal !important;
  border-color: neutrals-stroke-fields;
}

.pickerBtn:disabled:global(.Mui-disabled) {
  background-color: backgrounds-disabled;
  border-color: neutrals-stroke-fields;
}

.pickerBtn.error {
  border-color: red-500;
}

.pickerBtn.normal {
  height: 44px !important;
}

.pickerBtn .pickBtnContent {
  width: 100%;
  justify-content: space-between;
  height: 100%;
  padding: 0 0.75rem 0 0.75rem;
  border-radius: 0.25rem;
  border: 2px solid transparent;
}

.pickerBtn .pickBtnContent .pickBtnLabel {
  text-align: left;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
}

.pickerBtn .pickBtnContent .pickBtnIcon {
  color: neutrals-gray-alt;
}

.pickerBtn:focus-visible,
.pickerBtn:hover,
.pickerBtn.open {
  border-color: transparent !important;
}

.pickerBtn.error:focus-visible,
.pickerBtn.error:hover,
.pickerBtn.error.open {
  border-color: red-500 !important;
}

.pickerBtn:focus-visible .pickBtnContent,
.pickerBtn:hover .pickBtnContent,
.pickerBtn.open .pickBtnContent {
  border: 2px solid primary-primary-1;
}

.pickerBtn.error:focus-visible .pickBtnContent,
.pickerBtn.error:hover .pickBtnContent,
.pickerBtn.error.open .pickBtnContent {
  border: 2px solid red-500;
}

.pickerBtn:focus-visible,
.pickerBtn.open {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #eff8ff !important;
}

.pickerBtn.noValue .pickBtnLabel {
  color: neutrals-gray-alt;
}
