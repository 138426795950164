@value neutrals-white from "components/colours.module.css";
@value neutrals-dark from "components/colours.module.css";
@value grey-200 from "components/colours.module.css";
@value grey-100 from "components/colours.module.css";
@value grey-300 from "components/colours.module.css";
@value grey-400 from "components/colours.module.css";
@value grey-500 from "components/colours.module.css";
@value grey-600 from "components/colours.module.css";
@value red-500 from "components/colours.module.css";
@value state-colors-success from "components/colours.module.css";

.aiWorker {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  min-height: 2.375rem;
  padding-bottom: 0.25rem;
}

.aiSearchInput :global(.MuiOutlinedInput-root) :global(.MuiOutlinedInput-notchedOutline) {
  border-top-width: 0 !important;
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  border-radius: 0;
  box-shadow: none !important;
}

.aiSearchInput :global(.MuiOutlinedInput-root.Mui-disabled .MuiInputBase-input) {
  background-color: transparent;
}

.aiSearchInput.aiSearchInputGray :global(.MuiInputBase-root) {
  color: grey-500;
}

.actions {
  display: flex;
  align-items: center;
  padding-right: 0.25rem;
  align-self: flex-end;
  height: 2.25rem;
}

.actionBtn {
  height: 2.375rem;
  min-width: 1.5rem;
  background-color: transparent;
  justify-content: center !important;
  gap: 0;
  border-radius: 3px !important;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.actionBtn :global(.animate-spin) {
  height: 80%;
}

.clearStyle {
  all: initial;
}

.markdown {
  font-size: 1rem;
  font-family: 'Inter', sans-serif;
}

.markdown * {
  margin-bottom: 4px;
}

.markdown h1 {
  margin-bottom: 12px;
}

.markdown h2 {
  margin-bottom: 8px;
}

.retActions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
}

.retActions .retCheckActionBtn {
  border-color: red-500;
}
