.code {
  background: rgba(135, 131, 120, 0.15);
  border-radius: 0.25rem;
  border: 1px;
  padding: 0.1rem 0.5rem;
  color: red;
  font-family: Monospace;
  caret-color: black;
}

.bold {
  font-weight: 600;
}
