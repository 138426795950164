@value grey-300 from 'components/colours.module.css';

.command {
  position: absolute;
  background: white;
  border-radius: 0.25rem;
  box-shadow: rgb(15 15 15 / 5%) 0 0 0 1px, rgb(15 15 15 / 20%) 0 3px 6px, rgb(15 15 15 / 50%) 0 9px 24px;
  width: 28rem;
  max-height: 20rem;
  overflow: hidden auto;
  z-index: 1400;
}

.command .popupContent {
  width: 100%;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.command .popupContent .emojis {
  display: flex;
  flex-wrap: wrap;
}

.command .popupContent .emojis .emoji {
  width: 2.2rem;
  height: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 0.375rem;
  cursor: pointer;
}

.command .popupContent .emojis .emoji.highlighted {
  background-color: grey-300;
}

.command .popupContent .emojis .emoji .emojiTxt {
  font-size: 1.8rem;
  margin: auto;
}
