.commentBtn {
  padding: 0 0.375rem !important;
  height: 1.5rem;
  border-radius: 3px !important;
  width: 56px;
  justify-content: flex-start !important;
}

.commentBtn.overlap {
  width: auto;
  justify-content: center !important;
  background-color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.commentBtn.overlap:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
