@value blue-500 from "components/colours.module.css";
@value grey-200 from "components/colours.module.css";
@value grey-500 from "components/colours.module.css";
@value neutrals-white from "components/colours.module.css";
@value state-colors-success from "components/colours.module.css";
@value neutrals-stroke from "components/colours.module.css";

.row {
  display: flex;
  width: 100%;
}

.row .content {
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 0.875rem;
  gap: 0.25rem 0.5rem;
  overflow: hidden;
}

.row .content .label {
  color: grey-500;
}

.row .content .borderContent {
  display: flex;
  align-items: center;
  border: 1px solid grey-200;
  border-radius: 0.5rem;
  height: 2rem;
  font-variation-settings: 'wght' 500;
  font-weight: 500;
}

.row .content .field {
  padding: 0 0.75rem;
  border-right: 1px solid grey-200;
  height: 100%;
  display: flex;
  align-items: center;
}

.row .content .operator {
  padding: 0 0.75rem;
  border-right: 1px solid grey-200;
  height: 100%;
  display: flex;
  align-items: center;
}

.row .content .value {
  padding: 0 0.75rem;
  height: 100%;
  display: flex;
  align-items: center;
}
