.editorContainer {
  display: flex;
  align-items: flex-start;
}

.editorContainer .prefixWrap {
  margin-top: 0.25rem;
}

.editorContainer .editor {
  width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
  font-size: 14px;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 1rem;
  max-height: 70vh;
  min-height: 1rem !important;
  flex: 1;
}

.editorContainer .editor :global([data-slate-leaf='true']) {
  position: static !important;
}

.editorContainer .btns {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  align-self: flex-end;
  margin-bottom: 0.25rem;
}
