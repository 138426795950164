@value blue-200 from "components/colours.module.css";

.bottomSection {
  cursor: text;
  height: 1px;
  flex: 1;
  min-height: 2rem;
  position: relative;
}

.bottomSection::after {
  content: '';
  display: block;
  position: absolute;
  height: 2px;
  left: 4rem;
  right: 0.75rem;
  top: 0;
  background-color: transparent;
}

.bottomSection.dragOver::after {
  background-color: blue-200;
}
