@value neutrals-stroke from "components/colours.module.css";
@value neutrals-stroke-alt from "components/colours.module.css";
@value neutrals-stroke-fields from "components/colours.module.css";
@value primary-primary-1 from "components/colours.module.css";
@value neutrals-gray-alt from "components/colours.module.css";
@value red-500 from "components/colours.module.css";
@value backgrounds-disabled from "components/colours.module.css";
@value neutrals-dark from "components/colours.module.css";

.inputWrap {
  width: 100%;
  border-radius: 0.5rem;
}

.inputWrap :global(.MuiInputBase-root) {
  padding: 0;
  font-size: 0.875rem;
  color: neutrals-dark;
}

.inputWrap :global(.MuiInputBase-root) :global(.MuiInputBase-input:not(.MuiInputBase-inputSizeSmall)) {
  padding: 0.75rem 0.75rem;
  height: 20px;
}

.inputWrap :global(.MuiInputBase-root) :global(.MuiInputBase-input.MuiInputBase-inputSizeSmall) {
  padding: 0.5rem 0.75rem;
  height: 20px;
}

.inputWrap :global(.MuiInputBase-root) :global(.MuiInputBase-input)::placeholder {
  color: neutrals-gray-alt;
  opacity: 1;
}

.inputWrap.error :global(.MuiOutlinedInput-root) :global(.MuiOutlinedInput-notchedOutline) {
  border-color: red-500 !important;
}

.inputWrap :global(.MuiOutlinedInput-root) :global(.MuiOutlinedInput-notchedOutline) {
  border-color: neutrals-stroke-fields;
}

.inputWrap :global(.MuiOutlinedInput-root.Mui-disabled) :global(.MuiOutlinedInput-notchedOutline) {
  border-color: neutrals-stroke-fields;
}

.inputWrap :global(.MuiOutlinedInput-root):hover:not(:global(.Mui-disabled)) :global(.MuiOutlinedInput-notchedOutline) {
  border-width: 2px;
  border-color: primary-primary-1;
}

.inputWrap :global(.MuiOutlinedInput-root.Mui-disabled .MuiInputBase-input) {
  background-color: backgrounds-disabled;
}

.inputWrap :global(.MuiOutlinedInput-root):global(.Mui-focused) :global(.MuiOutlinedInput-notchedOutline) {
  border-color: primary-primary-1;
  border-width: 2px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #eff8ff;
}

.inputWrap .loadingIcon {
  position: absolute;
  bottom: 10px;
  right: 8px;
}

.inputWrap.small .loadingIcon {
  bottom: 6px;
}
