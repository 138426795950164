@value neutrals-dark-alt from "components/colours.module.css";
@value grey-100 from "components/colours.module.css";
@value grey-200 from "components/colours.module.css";
@value grey-600 from "components/colours.module.css";
.nameWrap {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.nameWrap .shortName {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  width: 1.25rem;
  min-width: 1.25rem;
  height: 1.25rem;
  border-radius: 100%;
  box-shadow: grey-200 0px 2px 4px;
  outline: grey-100 solid 1px;
  font-size: 0.625rem;
  color: grey-600;
  text-transform: uppercase;
}
.nameWrap .fullName {
  font-size: 0.875rem;
  font-weight: 600;
}
