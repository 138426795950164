@value neutrals-dark-alt from "components/colours.module.css";
@value backgrounds-neutral-main from "components/colours.module.css";

.selector {
  border: none;
  position: relative;
  height: 100%;
}

.button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: none;
  background-color: transparent;
  height: 100%;
  text-transform: capitalize;
  cursor: pointer;
  padding-left: 1rem;
  padding-right: 1rem;
  color: neutrals-dark-alt;
  white-space: nowrap;
}

.button:hover {
  background-color: lightgray;
}

.dropdown {
  position: absolute;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 0.25rem;
  padding: 0.5rem 0.25rem;
  width: 10rem;
  box-shadow: rgb(15 15 15 / 5%) 0 0 0 1px, rgb(15 15 15 / 20%) 0 3px 6px;
  opacity: 0;
  transition: opacity 0.25s;
  width: 200px;
}

.dropdown-title {
  color: rgba(55, 53, 47, 0.65);
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.5rem 0.25rem;
}

.selection {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.25rem;
  border-radius: 0.25rem;
  text-transform: capitalize;
  width: 100%;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: neutrals-dark-alt;
}

.selection:hover {
  background-color: backgrounds-neutral-main;
}

.selection-img {
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid lightgray;
  border-radius: 0.25rem;
}
