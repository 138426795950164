@value side-menu-width from "components/SideMenu.module.css";

.layout {
  width: side-menu-width;
}

.pulse {
  @apply border border-blue-300;
  animation: border-pulse 0.6s infinite;
}

@keyframes border-pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
