@value grey-500 from "components/colours.module.css";

.docHeaderContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
}

.docHeaderContainer .topSummary {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  flex-wrap: wrap;
  padding-bottom: 1rem;
}

.docHeaderContainer .topSummary .topSummaryItem.folder button {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
  max-width: 160px;
  font-size: 0.875rem;
}

.docHeaderContainer .topSummary .topSummaryItem.event {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
  font-size: 0.875rem;
}

.docHeaderContainer.docAccordionMode {
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
}

.docHeaderContainer.docAccordionMode .topSummary {
  gap: 0.25rem;
  flex-wrap: nowrap;
  padding-bottom: 0;
}

.docHeaderContainer.docAccordionMode .topSummary .topSummaryItem span:global(.truncate) {
  display: none;
}
