@value blue-200 from "components/colours.module.css";

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  position: relative;
  display: flex;
  width: 100%;
  padding-top: 0.3125rem;
}

.sub-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.container {
  container-type: inline-size;
  container-name: container;
}

@container container (max-width: 560px) {
  .container .sub-container :global(.sortable-element) {
    padding-left: 3.5rem;
  }

  .container .sub-container :global(.empty-element) {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .container .sub-container :global(.doc-header-container) {
    padding-left: 3rem;
  }

  .container .sub-container :global(.editor-bottom-section::after) {
    left: 3.5rem;
  }
}

@container container (max-width: 400px) {
  .container .sub-container :global(.sortable-element) {
    padding-right: 1rem;
  }

  .container .sub-container :global(.empty-element) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .container .sub-container :global(.doc-header-container) {
    padding-left: 1rem;
  }

  .container .sub-container :global(.editor-bottom-section::after) {
    right: 1rem;
  }
}

:global(.block-uuid-high-light) {
  transition: background 300ms ease-in 0s;
  background: blue-200;
}
