@value neutrals-white from "components/colours.module.css";
@value neutrals-white from "components/colours.module.css";
@value blue-500 from "components/colours.module.css";

.docAccordion:global(.MuiAccordion-root) {
  border-radius: 0rem !important;
  margin: 0 !important;
  box-shadow: none !important;
}

.docAccordion:global(.MuiAccordion-root):before {
  display: none;
}

.docAccordion:global(.MuiAccordion-root) :global(.MuiAccordionSummary-root) {
  position: sticky;
  top: 0;
  z-index: 1;
  padding-right: 2rem;
  background-color: neutrals-white;
  border-radius: 0rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.docAccordion:global(.MuiAccordion-root) :global(.MuiAccordionSummary-root) :global(.MuiAccordionSummary-content) {
  margin: 0;
  padding: 0.5rem 0rem 0.5rem 0rem;
  width: 100%;
}

.docAccordion:global(.MuiAccordion-root) :global(.MuiAccordionDetails-root) {
  padding: 1rem 0 0 0;
}

.accordionContent.loading {
  height: 300px;
}

.editor {
  min-height: 300px;
}

.docHeader {
  display: flex;
  padding-right: 1rem;
  padding: 0.5rem 1rem;
}

.docHeader .docTitle {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  padding-bottom: 0.5rem;
  text-overflow: ellipsis;
  font-variation-settings: 'wght' 500;
}

.docHeader .docTitle.docTitleFocused {
  color: blue-500;
}

.docAccordion {
  container-type: inline-size;
  container-name: docAccordion;
}

@container docAccordion (max-width: 420px) {
  .docAccordion .docHeader {
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .docAccordion:global(.MuiAccordion-root) :global(.MuiAccordionSummary-root) {
    padding-left: 0;
    padding-right: 1rem;
  }
}
