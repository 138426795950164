@value blue-500 from "components/colours.module.css";
@value grey-50 from "components/colours.module.css";
@value neutrals-white from "components/colours.module.css";
@value state-colors-success from "components/colours.module.css";
@value neutrals-stroke from "components/colours.module.css";

.baseSwitch:global(.MuiFormControlLabel-root) {
  width: auto;
  height: 2rem;
  margin-right: 0;
  margin-left: 0;
  padding: 0;
}

.baseSwitch:global(.MuiFormControlLabel-root) .spinner {
  width: 2rem;
  height: 1.25rem;
}

.baseSwitch:global(.MuiFormControlLabel-root) :global(.MuiFormControlLabel-label) {
  font-size: inherit;
  color: inherit;
  flex: 1;
}

.baseSwitch :global(.MuiSwitch-root) {
  padding: 0;
  width: auto;
  height: auto;
}

.baseSwitch :global(.MuiSwitch-root) :global(.MuiSwitch-track) {
  width: 2rem;
  height: 1.25rem;
  border-radius: 0.625rem;
  background-color: neutrals-stroke;
  opacity: 1;
  border: 0;
  transition: background-color 200ms;
}

.baseSwitch :global(.MuiSwitch-root) :global(.MuiSwitch-switchBase) {
  width: 0.75rem;
  height: 0.75rem;
  padding: 0;
  background-color: neutrals-white;
  transform: translateX(0.25rem) translateY(0.25rem);
}

.baseSwitch :global(.MuiSwitch-root) :global(.MuiSwitch-switchBase) :global(.MuiSwitch-thumb) {
  display: none;
}

.baseSwitch :global(.MuiSwitch-root) :global(.MuiSwitch-switchBase):global(.Mui-checked) {
  transform: translateX(1rem) translateY(0.25rem);
  background-color: neutrals-white;
}

.baseSwitch :global(.MuiSwitch-root) :global(.MuiSwitch-switchBase):global(.Mui-checked) + :global(.MuiSwitch-track) {
  background-color: state-colors-success;
  opacity: 1;
}

.baseSwitch :global(.MuiSwitch-root) :global(.MuiSwitch-switchBase):global(.Mui-disabled) {
  background-color: grey-50;
}

.baseSwitch :global(.MuiSwitch-root) :global(.MuiSwitch-switchBase):global(.Mui-disabled) + :global(.MuiSwitch-track) {
  opacity: 0.75;
}

.baseSwitch.hasLabel {
  gap: 0.5rem;
}
