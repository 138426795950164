@value blue-50 from "components/colours.module.css";
@value blue-500 from "components/colours.module.css";
@value blue-600 from "components/colours.module.css";
@value grey-100 from "components/colours.module.css";
@value grey-200 from "components/colours.module.css";
@value grey-300 from "components/colours.module.css";
@value grey-500 from "components/colours.module.css";
@value orange-400 from "components/colours.module.css";
@value slate-100 from "components/colours.module.css";
@value yellow-400 from "components/colours.module.css";

.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;

  padding: 1.25rem 1.25rem 0;

  border-bottom: 1px solid grey-100;
}

.header-title {
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
}

.opportunity-icon {
  height: 1.75rem;
  width: 1.75rem;
  fill: orange-400;
  color: orange-400;
}

.header-navbar {
  display: flex;
  column-gap: 1rem;
  justify-content: space-between;
  align-items: flex-start;
}

.new-note-button {
  display: flex;
  align-items: center;

  padding: 0.25rem 0.5rem;

  background-color: blue-600;
  border-radius: 0.25rem;
  text-decoration: none;
}

.new-note-button:hover {
  background-color: blue-500;
}

.new-note-button:disabled {
  cursor: not-allowed;
}

.container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  max-width: 1200px;
  padding: 1.5rem 2.5rem;
  margin: auto;
}

.metrics {
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
}

.metric-block {
  display: flex;
  column-gap: 0.5rem;
  align-items: center;

  padding: 0.75rem 1rem;

  width: 30%;
  max-width: 20rem;

  background-color: slate-100;
  border-radius: 0.5rem;
}

.documents {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.document {
  display: flex;
  column-gap: 1rem;
  justify-content: space-between;
  min-height: 1.75rem;
}

.document-left {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.document-icon {
  height: 1rem;
  width: 1.1875rem;
  color: grey-500;
}

.document-link {
  text-decoration: none;
}
.document-link:hover {
  text-decoration: underline;
}

.star-icon {
  height: 1.125rem;
  fill: yellow-400;
}

.event-time-pill {
  padding: 0.15rem 0.75rem;
  color: blue-500;
  background-color: blue-50;
  border-radius: 0.4rem;
}

.document-menu-button {
  display: flex;
  align-items: center;

  border-radius: 0.25rem;
}
.document-menu-button:hover {
  background-color: grey-200;
}

.document-menu-icon {
  height: 1.2rem;
  stroke: grey-500;
}
