@value grey-500 from "components/colours.module.css";

.filterPopupContainer {
  padding: 0.625rem 1rem 1rem 1rem;
  width: 280px !important;
}

.filterPopupContainer :global(.MuiFormControlLabel-root) {
  width: 100%;
  margin-right: 0;
}

.filterPopupContainer :global(.MuiFormControlLabel-root .MuiTypography-root) {
  color: grey-500;
}
