@value neutrals-stroke from "components/colours.module.css";
@value neutrals-gray from "components/colours.module.css";
@value neutrals-white from "components/colours.module.css";

.command {
  position: absolute;
  opacity: 0;
  z-index: 2;
  background: neutrals-white;
  border-radius: 0.5rem;
  border: 1px solid neutrals-stroke;
  box-shadow: 0px 6px 28px rgba(21, 41, 82, 0.08);
  pointer-events: auto;
  width: 22.275rem;
  padding: 0.5rem;
  overflow: hidden auto;
}

.block-title {
  line-height: 1.75rem;
  font-size: 1rem;
  color: neutrals-gray;
  padding: 0 0.5rem;
}
