@value grey-50 from "components/colours.module.css";
@value grey-200 from "components/colours.module.css";

.oauth-link {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0.75rem 0.5rem;

  text-decoration: none;

  border: 1px solid grey-200;
  border-radius: 0.25rem;
}

.oauth-link:hover {
  background-color: grey-50;
}

.link-left {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.logo {
  height: 3rem;
  width: 3rem;
}

.link-text {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  align-items: flex-start;
}

.chevron {
  height: 1.75rem;
  width: 1.75rem;
  stroke-width: 2;
}

.skip-button-container {
  display: flex;
  justify-content: flex-end;
}

.skip-button {
  padding: 0.5rem;
  border-radius: 0.25rem;
}

.skip-button:hover {
  background-color: grey-50;
}
