@value neutrals-dark-alt from "components/colours.module.css";

.container {
  min-width: 10rem;
}

.button {
  display: flex;
  align-items: center;
}

.icon {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.5rem;
  margin-right: 0.25rem;
}

.text {
  color: neutrals-dark-alt;
}
