@value neutrals-dark-alt from "components/colours.module.css";
@value neutrals-stroke from "components/colours.module.css";
.button {
  width: 2.5rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: neutrals-dark-alt;
}

.button:hover {
  background-color: lightgray;
}
