@value neutrals-dark from "components/colours.module.css";
@value backgrounds-neutral-main from "components/colours.module.css";

.menuContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 32px;
}

.menuContent .menuList {
  padding: 0.5rem;
  width: 100%;
  height: 100%;
  overflow: hidden auto;
}

.menuContent .menuList :global(.MuiMenuItem-root) {
  min-width: 2rem;
  border-radius: 0.5rem;
  padding: 0.375rem 0.75rem;
}

.menuContent .menuList :global(.MuiMenuItem-root.Mui-focusVisible) {
  background-color: backgrounds-neutral-main;
}

.menuContent .menuList :global(.MuiMenuItem-root .MuiListItemIcon-root) {
  min-width: 1.75rem;
}

.menuContent .menuList :global(.MuiMenuItem-root .MuiListItemText-root .MuiTypography-root) {
  color: neutrals-dark;
  font-size: 0.75rem;
}
