@value blue-400 from "components/colours.module.css";

.container {
  width: 100%;
  position: relative;
  user-select: none;
  padding: 0.25rem 0.5rem 0 0.5rem;
}

.container.selected {
  outline: 2px solid blue-400;
}

.container .text {
  display: none;
}
