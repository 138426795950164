@value neutrals-dark from "components/colours.module.css";
@value neutrals-white from "components/colours.module.css";
@value neutrals-stroke-fields from "components/colours.module.css";
@value neutrals-stroke from "components/colours.module.css";
@value blue-50 from "components/colours.module.css";
@value blue-500 from "components/colours.module.css";
@value primary-primary from "components/colours.module.css";
@value primary-primary-1 from "components/colours.module.css";
@value state-colors-error from "components/colours.module.css";
@value state-colors-error-alt from "components/colours.module.css";
@value backgrounds-neutral-alt from "components/colours.module.css";
@value backgrounds-neutral-light from "components/colours.module.css";
@value grey-600 from "components/colours.module.css";
@value grey-100 from "components/colours.module.css";
@value orange-400 from "components/colours.module.css";
@value state-colors-success from "components/colours.module.css";
@value green-50 from "components/colours.module.css";

.baseBtn:global(.MuiButton-root) {
  padding: 0.25rem 0.5rem;
  height: 2rem;
  gap: 0.25rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  max-width: 100%;
  border-radius: 0.5rem;
  text-align: center;
  text-transform: none;
  min-width: auto;
  font-family: 'Inter', sans-serif;
}

.baseBtn.iconBtn {
  padding: 0;
  height: 1.5rem;
  width: 1.5rem;
  min-width: 1.5rem;
  border-radius: 50%;
  justify-content: center;
}

.baseBtn:global(.MuiButton-root):hover {
  box-shadow: none;
}

.baseBtn:global(.MuiButton-containedPrimary) {
  background-color: primary-primary;
}

.baseBtn:global(.MuiButton-containedPrimary):hover {
  background-color: primary-primary-1;
}

.baseBtn:global(.MuiButton-containedSecondary) {
  color: neutrals-dark;
  background-color: backgrounds-neutral-alt;
}

.baseBtn:global(.MuiButton-containedSecondary):hover {
  background-color: backgrounds-neutral-light;
}

.baseBtn:global(.MuiButton-containedError) {
  background-color: state-colors-error;
}

.baseBtn:global(.MuiButton-containedError):hover {
  background-color: state-colors-error-alt;
}

.baseBtn:global(.MuiButton-textPrimary) {
  color: blue-500;
}

.baseBtn:global(.MuiButton-outlinedPrimary) {
  color: blue-500;
  border-color: blue-500;
}

.baseBtn:global(.MuiButton-outlinedPrimary):hover {
  background-color: blue-50;
}

.baseBtn:global(.MuiButton-outlinedSecondary) {
  color: neutrals-dark;
  border-color: neutrals-stroke !important;
}

.baseBtn:global(.MuiButton-outlinedSecondary):hover {
  border-color: neutrals-stroke-fields;
  background-color: neutrals-white;
}

.baseBtn:global(.MuiButton-outlinedSuccess) {
  color: state-colors-success;
  border-color: state-colors-success !important;
}

.baseBtn:global(.MuiButton-outlinedSuccess):hover {
  border-color: state-colors-success;
  background-color: green-50;
}

.baseBtn:global(.MuiButton-textSecondary) {
  color: grey-600;
}

.baseBtn:global(.MuiButton-textSecondary):hover {
  background-color: grey-100;
}

.baseBtn .btnContent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: inherit;
  max-width: 100%;
}

.baseBtn.loading .btnContent {
  opacity: 0;
}

.baseBtn .animateSpin {
  height: 55%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  color: orange-400;
}
