@value neutrals-white from "components/colours.module.css";

.attendeesList {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  margin-right: -0.125rem;
}

.attendeesList .attendee {
  border: 2px solid neutrals-white;
  margin-left: -0.5rem;
  color: neutrals-white;
}

.attendeesList .attendee.extraAttendees {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: default;
}
