@value red-500 from "components/colours.module.css";
@value neutrals-gray from "components/colours.module.css";
.formField {
  display: flex;
  width: 100%;
}
.formField.vertical {
  flex-direction: column;
}
.formField.vertical .field {
  overflow: visible;
  padding: 0;
}
.formField.vertical .label {
  padding: 0.25rem 0;
}
.formField .label {
  color: neutrals-gray;
  font-size: 0.875rem;
  line-height: 1.5rem;
  padding: 0.625rem 0;
}
.formField .field {
  width: 100%;
  flex: 1;
  overflow: hidden;
  padding: 0.25rem;
}
.formField .field .fieldWrap {
  width: 100%;
  display: flex;
  align-items: center;
}
.formField .field .error {
  color: red-500;
  font-size: 0.75rem;
}
