@value neutrals-dark-alt from "components/colours.module.css";
@value neutrals-stroke from "components/colours.module.css";
@value backgrounds-neutral-main from "components/colours.module.css";
@value backgrounds-neutral-alt from "components/colours.module.css";
@value neutrals-white from "components/colours.module.css";

.button {
  width: 2.5rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: neutrals-white;
  color: neutrals-dark-alt;
}

.border-left {
  border-left: 1px solid neutrals-stroke;
}

.button:hover {
  background-color: backgrounds-neutral-main;
}

.button.button-active {
  background-color: backgrounds-neutral-alt;
}

.button-disabled {
  cursor: default;
}
