@value blue-200 from "components/colours.module.css";
@value blue-400 from "components/colours.module.css";
@value grey-200 from "components/colours.module.css";
@value grey-400 from "components/colours.module.css";
@value grey-500 from "components/colours.module.css";

.mention {
  padding: 0 0 0 0.125rem;
  color: grey-500;
}

.mention.selected {
  outline: 2px solid blue-400;
}

.mention:global(.mention-init) {
  background: grey-200;
  border-radius: 0.25rem;
}

.mention:global(.high-light) {
  transition: background 300ms ease-in 0s;
  background: blue-200;
}
