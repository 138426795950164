@value blue-400 from "components/colours.module.css";

.figure {
  display: flex;
  max-width: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  transition-property: width;
  transition-duration: 270ms;
  transition-timing-function: ease;
}

.figure .imgWrap {
  position: relative;
  max-width: 100%;
  user-select: none;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.figure .imgWrap .img {
  display: block;
  border-radius: 4px;
  pointer-events: auto;
  user-select: none;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
}

.figure .imgWrap .img.imgParentSize {
  width: 100%;
}

.figure .imgWrap .img.selected {
  outline: 2px solid blue-400;
}

.figure .imgWrap.dragging {
  cursor: col-resize;
}

.figure .imgWrap.dragging .dragHandlerWrap,
.figure .imgWrap:hover .dragHandlerWrap {
  opacity: 1;
}

.figure .imgWrap .dragHandlerWrap {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  z-index: 1;
  height: 100%;
  width: 15px;
  cursor: col-resize;
  opacity: 0;
}

.figure .imgWrap .dragHandlerWrap.right {
  left: unset;
  right: 0;
}

.figure .imgWrap .dragHandlerWrap .dragHandler {
  opacity: 1;
  transition: opacity 300ms ease-in 0s;
  border-radius: 20px;
  background: rgba(15, 15, 15, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.9);
  width: 6px;
  height: 48px;
  max-height: 50%;
}

.figure .imgWrap .commentBtnWrap {
  position: absolute;
  right: 4px;
  top: 4px;
  opacity: 0;
}

.figure .imgWrap:hover .commentBtnWrap {
  opacity: 1;
}

.selected {
  outline: 2px solid blue-400;
}
