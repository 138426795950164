@value red-500 from "components/colours.module.css";

.box-left {
  margin-left: 12px;
}

.box-right {
  margin-right: 12px;
}

.box {
  max-width: 400px;
  background: #edf0f5;
  padding: 10px;
  text-align: left;
  font-family: arial;
  position: relative;
  border-radius: 10px;
}

/* .sb1:before {
  content: '';
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid #edf0f5;
  border-right: 10px solid transparent;
  border-top: 10px solid #edf0f5;
  border-bottom: 10px solid transparent;
  right: -19px;
} */

.sb2:before {
  content: '';
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid #edf0f5;
  border-top: 10px solid #edf0f5;
  border-bottom: 10px solid transparent;
  left: -19px;
}

/*   
    .sb3:before {
      content: "";
      width: 0px;
      height: 0px;
      position: absolute;
      border-left: 10px solid #EDF0F5;
      border-right: 10px solid transparent;
      border-top: 10px solid #EDF0F5;
      border-bottom: 10px solid transparent;
      left: 19px;
      bottom: -19px;
    }
    
    .sb4:before {
      content: "";
      width: 0px;
      height: 0px;
      position: absolute;
      border-left: 10px solid transparent;
      border-right: 10px solid #EDF0F5;
      border-top: 10px solid #EDF0F5;
      border-bottom: 10px solid transparent;
      right: 19px;
      bottom: -19px;
    } */

.error {
  color: red-500;
}
