@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
    }
  }
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter-VariableFont_slnt,wght.ttf') format('woff2-variations');
}

@layer utilities {
  .font-thin {
    font-variation-settings: 'wght' 100;
  }

  .font-extralight {
    font-variation-settings: 'wght' 200;
  }

  .font-light {
    font-variation-settings: 'wght' 300;
  }

  .font-normal {
    font-variation-settings: 'wght' 400;
  }

  .font-medium {
    font-variation-settings: 'wght' 500;
  }

  .font-semibold {
    font-variation-settings: 'wght' 600;
  }

  .font-bold {
    font-variation-settings: 'wght' 700;
  }

  .font-extrabold {
    font-variation-settings: 'wght' 800;
  }

  .font-black {
    font-variation-settings: 'wght' 900;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border: 0 solid;
  @apply border-gray-200;
}

body {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: 'Inter', sans-serif;
  font-variation-settings: 'wght' 400;
  overflow: hidden;
}

body.dragging {
  user-select: none;
}

a {
  color: inherit;
}

button {
  background-color: transparent;
  cursor: pointer;
}

:focus {
  outline: none;
  outline-offset: 0;
}

/* disable lastpass from appearing in the discovery question autocomplete input */
/* div[data-lastpass-icon-root] {
  display: none;
} */

input.placeholder:disabled {
  background-color: rgb(255, 255, 255);
}

textarea::placeholder,
input::placeholder {
  color: rgb(175, 185, 204);
}

.MuiTooltip-popper.MuiTooltip-popperInteractive {
  /* make tooltip not interactive with mouse */
  pointer-events: none;
}
