@value backgrounds-neutral-alt from "components/colours.module.css";
@value neutrals-dark from "components/colours.module.css";
@value neutrals-gray from "components/colours.module.css";

.menu {
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  height: 4rem !important;
  padding: 0.5rem !important;
  border: none;
  border-radius: 0.5rem;
  width: 100%;
  cursor: pointer;
  text-transform: none;
}

.menu:hover {
  background-color: transparent !important;
}

.menu.selected {
  background-color: backgrounds-neutral-alt !important;
}

.img {
  width: 3rem;
  height: 3rem;
  border-radius: 0.5rem;
  margin-right: 0.5rem;
  z-index: 1;
}

.text-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.text-wrap .text {
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: neutrals-dark;
}

.text-wrap .description {
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.004rem;
  color: neutrals-gray;
}
