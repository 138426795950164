.root {
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
}

.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5rem 0 0 0;
}

.container {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;

  width: 50%;
  min-width: 20rem;
  max-width: 50rem;
}
