.comment {
  border-bottom: 2px solid #ffd400;
}

.comment[class*='comment-'] {
  background: rgba(255, 212, 0, 0.7);
}

.comment.comment-0 {
  background-color: transparent !important;
  border-bottom: none;
}

.comment.comment-1 {
  background-color: rgba(255, 212, 0, 0.14) !important;
}

.comment.comment-2 {
  background-color: rgba(255, 212, 0, 0.4) !important;
}

.comment.comment-3 {
  background-color: rgba(255, 212, 0, 0.7) !important;
}

.comment.commentActive {
  background: rgba(255, 212, 0, 0.8) !important;
}
