@value neutrals-dark from "components/colours.module.css";

.editorContainer {
  display: flex;
  align-items: flex-start;
  width: 100%;
  min-height: 2rem;
}

.editorContainer .editor {
  width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
  min-height: 2rem !important;
  padding: 0.22rem 0.5rem 0.28rem 0 !important;
}

.editorContainer .editor :global([data-slate-placeholder='true']) {
  padding: 0.22rem 0 0.28rem 0 !important;
}

.editorContainer .editor :global([data-slate-leaf='true']) {
  position: static !important;
}

[data-slate-placeholder='true'] {
  user-select: none;
  pointer-events: none;
}

.commentBtn {
  width: 2.5rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: neutrals-dark-alt;
  border-left: 1px solid neutrals-stroke;
}

.commentBtn:hover {
  background-color: lightgray;
}
