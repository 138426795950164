.rightSideDrawer {
  pointer-events: none;
  max-width: 100%;
  overflow: hidden;
  height: 100%;
}

.rightSideDrawer .rightSideDrawerContent {
  pointer-events: all;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
