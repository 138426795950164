.container {
  display: flex;
  position: absolute;
  display: flex;
  align-items: center;
  height: 2.5rem;
  background: white;
  border-radius: 5px;
  z-index: 1400;
  box-shadow: rgb(15 15 15 / 5%) 0 0 0 1px, rgb(15 15 15 / 20%) 0 3px 6px, rgb(15 15 15 / 50%) 0 9px 24px;
}

.icon {
  width: 1rem;
  height: 1rem;
}
