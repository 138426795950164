@value neutrals-dark from "components/colours.module.css";
@value neutrals-dark-alt from "components/colours.module.css";
@value state-colors-error from "components/colours.module.css";
@value state-colors-error-light from "components/colours.module.css";
@value state-colors-error-light-alt from "components/colours.module.css";
.formContainer {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.formContainer .formBody {
  color: neutrals-dark;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 1.5rem;
  overflow: hidden auto;
}

.formContainer .formFooter {
  padding: 1rem 1.5rem 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
}

.formContainer .formFooter .formBtn {
  height: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  gap: 0.25rem;
}
