.editor {
  max-height: 20rem;
  overflow-y: auto;
  width: 100%;
  height: 100%;
}

.editor::-webkit-scrollbar {
  display: none;
}

.editor > * > * li {
  margin-left: 1rem;
}

.editor > * > * a {
  @apply text-blue-500;
}
