@value blue-100 from "components/colours.module.css";
@value blue-500 from "components/colours.module.css";
@value blue-600 from "components/colours.module.css";
@value grey-100 from "components/colours.module.css";
@value grey-200 from "components/colours.module.css";
@value grey-500 from "components/colours.module.css";

.layout {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  width: 20vw;
  min-width: 25rem;
  padding: 1rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.document-icon-container {
  display: flex;
  align-items: center;

  padding: 0.8rem;

  background-color: blue-100;
  border-radius: 50%;
}

.document-icon {
  height: 1.5rem;
  color: blue-600;
}

.close-button {
  display: flex;
  padding: 0.2rem 0.5rem;
  border-radius: 50%;
}

.close-button:hover {
  background-color: grey-100;
}

.close-icon {
  height: 1.25rem;
  color: grey-500;
}

.name-container {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}

.text-input {
  padding: 0.5rem;

  border: 1px solid grey-200;
  border-radius: 0.5rem;
}

.buttons-container {
  display: flex;
  column-gap: 0.75rem;
}

.buttons-container > * {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
}

.cancel-button {
  padding: 0.5rem 0.75rem;

  background-color: grey-100;
  border-radius: 0.25rem;
}

.cancel-button:hover {
  background-color: grey-200;
}

.cancel-button:disabled {
  cursor: not-allowed;
}

.submit-button {
  padding: 0.5rem 0.75rem;

  background-color: blue-600;
  border-radius: 0.25rem;
}

.submit-button:hover {
  background-color: blue-500;
}

.submit-button:disabled {
  cursor: not-allowed;
}
