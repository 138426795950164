@value primary-primary from 'components/colours.module.css';
@value blue-50 from 'components/colours.module.css';
@value neutrals-gray from 'components/colours.module.css';

.tabs.tabsContainer {
  min-height: 2.25rem;
}

.tabs.tabsContainer .tab {
  min-height: 2.25rem;
  padding: 0.25rem 1rem;
  text-transform: none;
  border-radius: 0.375rem;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
  color: neutrals-gray;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

.tabs.tabsContainer .tab:global(.Mui-selected) {
  color: primary-primary;
}

.tabs.tabsContainer .tab:disabled {
  opacity: 0.5;
}

.tabs.tabsContainer .tab :global(.MuiTab-iconWrapper) {
  margin: 0;
}

.tabs.tabsContainer.vertical .tab:global(.Mui-selected) {
  background-color: blue-50;
}

.tabs.tabsContainer.vertical :global(.MuiTabs-flexContainer) {
  gap: 0.5rem;
}

.tabs.tabsContainer.vertical :global(.MuiTabs-indicator) {
  display: none;
}

.tabs.tabsContainer.vertical .tab {
  padding: 0.25rem 0.75rem;
  justify-content: flex-start;
}
