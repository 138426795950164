.pageContainer {
  display: flex;
}

.pageContainer .leftSection {
  padding: 1rem 0.5rem;
  width: max(22vw, 210px);
  max-width: 260px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
}

.pageContainer .leftSection .leftTabs {
  margin: 20vh 0 80vh 0;
}

.pageContainer .leftSection .leftTabs .squareBlock {
  width: 0.5rem;
  flex-shrink: 0;
  height: 0.5rem;
  margin: 0.25rem !important;
  background: currentColor;
  border-radius: 1px;
}

.pageContainer .rightSection {
  display: flex;
  justify-content: center;
  height: 100vh;
  flex: 1;
  overflow: hidden auto;
}

.pageContainer .rightSection .rightSectionContent {
  width: min(100%, 56rem);
  padding: 2rem 1rem 1rem 1rem;
}
