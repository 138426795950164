@value blue-200 from "components/colours.module.css";
@value blue-400 from "components/colours.module.css";

.aiWorkerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  min-height: 2.375rem;
  border-radius: 4px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.aiWorkerContainer.selected {
  outline: 2px solid blue-400;
}

.aiWorkerContainer .addMoreBtn {
  width: fit-content;
  align-self: flex-end;
}

:global(.ai-worker.high-light) {
  transition: outline 300ms ease-in 0s;
  outline: 2px solid blue-400;
}

:global(.ai-worker.high-light .assigned-user-btn) {
  background-color: transparent !important;
  outline: none !important;
  box-shadow: none !important;
}
