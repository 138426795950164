@value grey-200 from 'components/colours.module.css';
@value grey-100 from 'components/colours.module.css';
@value grey-300 from 'components/colours.module.css';
@value grey-500 from 'components/colours.module.css';
@value slate-100 from "components/colours.module.css";

.container {
  display: flex;
  flex: 1;
  height: 1px;
  width: 100%;
}

.left-element {
  width: 1px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.right-element {
  flex: 1;
  width: 1px;
}

.separator {
  flex-shrink: 0;
  height: 100%;
  width: 1rem;
  background-color: grey-300;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: col-resize;
  gap: 0.125rem;
  padding: 0;
}

.bar {
  width: 0.125rem;
  height: 1.25rem;
  stroke-width: 3;
  background-color: grey-500;
}

.container.scrollable .left-element,
.container.scrollable .right-element {
  overflow-y: auto;
}

.container .right-section {
  display: flex;
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.container.show-right :global(.context-panel-left-content) {
  padding-left: 0;
  padding-right: 0;
}

.meetings-wrap {
  width: 100%;
  height: 100%;
  overflow: hidden auto;
  background-color: grey-100;
}
