@value blue-200 from "components/colours.module.css";

.sortable-element {
  display: flex;
  align-items: center;
  position: relative;
}

.sortable-element.sortable-element-drag-over .sortable-element-wrap::after {
  content: '';
  display: block;
  position: absolute;
  height: 2px;
  left: 0;
  right: 0;
  top: 0;
  background-color: blue-200;
}

.sortable-menu {
  display: flex;
  align-items: center;
  position: absolute;
  left: -3rem;
  top: 0.25rem;
  opacity: 0;
}

.sortable-button {
  padding: 0.2rem;
  text-align: center;
  border: none;
  line-height: 1.5rem;
  width: 1.25rem;
  height: 1.5rem;
  font-size: 1.5rem;
  color: gray;
  background-color: transparent;
  cursor: grab;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  border-radius: 0.25rem;
}

.sortable-button:hover {
  background-color: lightgray;
}

.sortable-element:hover .sortable-menu {
  opacity: 1;
}

.basic-element-wrap {
  max-width: 100%;
  width: 100%;
  position: relative;
}

.basic-element-wrap:global(.has-comments) {
  max-width: calc(100% - 60px);
}

.basic-element-wrap.basic-element-table {
  margin-left: -0.75rem;
  padding-left: 0.75rem;
  padding-top: 0.12rem;
}

:global(.has-comments).basic-element-wrap.basic-element-table {
  overflow-x: auto;
}

.basic-element-wrap.basic-element-task .action-btns {
  right: -31px;
}

.sortable-element-wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.sortable-element-wrap.sortable-element-discovery-question,
.sortable-element-wrap.sortable-element-product-gap {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.sortable-element-wrap.sortable-element-table {
  margin-top: 0.5rem;
}

.sortable-element-wrap.sortable-element-task {
  margin-top: 0;
  margin-bottom: 0;
}

.sortable-element-wrap.sortable-element-image {
  margin-top: 4px;
  margin-bottom: 4px;
}

.basic-element-wrap .action-btns {
  opacity: 0;
  display: none;
  position: absolute;
  right: 4px;
  top: 4px;
  z-index: 10;
  user-select: none;
}

.basic-element-wrap.basic-element-task .action-btns {
  top: 3px;
}

.basic-element-wrap:hover .action-btns {
  opacity: 1;
  display: block;
}

.comment {
  border-radius: 4px;
}

.comment[class*='comment-'] {
  outline: 2px solid rgba(255, 212, 0, 0.8);
}

.comment.comment-0,
.basic-element-wrap.basic-element-image.comment {
  outline: none !important;
}

.comment.comment-1 {
  outline: 2px solid rgba(255, 212, 0, 0.4) !important;
}

.comment.comment-2 {
  outline: 2px solid rgba(255, 212, 0, 0.7) !important;
}

.comment.comment-3 {
  outline: 2px solid rgba(255, 212, 0, 0.8) !important;
}

.comment.commentActive {
  outline: 2px solid #ffd400 !important;
}
