@value black from "components/colours.module.css";

@value green-600 from "components/colours.module.css";

@value grey-100 from "components/colours.module.css";
@value grey-200 from "components/colours.module.css";
@value grey-300 from "components/colours.module.css";
@value grey-400 from "components/colours.module.css";
@value grey-500 from "components/colours.module.css";
@value grey-600 from "components/colours.module.css";
@value grey-700 from "components/colours.module.css";
@value grey-800 from "components/colours.module.css";
@value grey-900 from "components/colours.module.css";

@value red-500 from "components/colours.module.css";

@value slate-100 from "components/colours.module.css";
@value slate-200 from "components/colours.module.css";
@value slate-300 from "components/colours.module.css";
@value slate-400 from "components/colours.module.css";
@value slate-500 from "components/colours.module.css";
@value slate-600 from "components/colours.module.css";
@value slate-700 from "components/colours.module.css";
@value slate-800 from "components/colours.module.css";
@value slate-900 from "components/colours.module.css";

@value yellow-500 from "components/colours.module.css";

.xxs {
  font-size: 0.5rem;
  line-height: 0.75rem;
}
.xs {
  font-size: 0.75rem;
  line-height: 1.1rem;
}
.sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.xl-2 {
  font-size: 1.5rem;
  line-height: 2rem;
}
.xl-3 {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.thin {
  font-variation-settings: 'wght' 100;
}
.extra-light {
  font-variation-settings: 'wght' 200;
}
.light {
  font-variation-settings: 'wght' 300;
}
.medium {
  font-variation-settings: 'wght' 500;
}
.semi-bold {
  font-variation-settings: 'wght' 600;
}
.bold {
  font-variation-settings: 'wght' 700;
}
.extra-bold {
  font-variation-settings: 'wght' 800;
}
.black {
  font-variation-settings: 'wght' 900;
}

.italic {
  font-style: italic;
}

.text-black {
  color: black;
}

.text-green-600 {
  color: green-600;
}

.text-grey-100 {
  color: grey-100;
}
.text-grey-200 {
  color: grey-200;
}
.text-grey-300 {
  color: grey-300;
}
.text-grey-400 {
  color: grey-400;
}
.text-grey-500 {
  color: grey-500;
}
.text-grey-600 {
  color: grey-600;
}
.text-grey-700 {
  color: grey-700;
}
.text-grey-800 {
  color: grey-800;
}
.text-grey-900 {
  color: grey-900;
}

.text-red-500 {
  color: red-500;
}

.text-slate-100 {
  color: slate-100;
}
.text-slate-200 {
  color: slate-200;
}
.text-slate-300 {
  color: slate-300;
}
.text-slate-400 {
  color: slate-400;
}
.text-slate-500 {
  color: slate-500;
}
.text-slate-600 {
  color: slate-600;
}
.text-slate-700 {
  color: slate-700;
}
.text-slate-800 {
  color: slate-800;
}
.text-slate-900 {
  color: slate-900;
}

.text-white {
  color: white;
}

.text-yellow-500 {
  color: yellow-500;
}
