.alert {
  position: fixed;
  top: 1rem;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 50%;
  max-width: 30rem;
  z-index: 99999;
}
