.container {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  overflow: hidden;
  cursor: default;
}

.container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
