@value neutrals-dark from "components/colours.module.css";
@value neutrals-dark-alt from "components/colours.module.css";
@value state-colors-error from "components/colours.module.css";
@value state-colors-error-light from "components/colours.module.css";
@value state-colors-error-light-alt from "components/colours.module.css";

.modalContainer {
  max-width: 100%;
  max-height: 90dvh;
  display: flex;
  flex-direction: column;
}

.modalContainer .modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem 0.5rem 1.5rem;
}

.modalContainer .modalHeader .titleWrap {
  flex: 1;
  display: flex;
  align-items: center;
  height: 100%;
}

.modalContainer .modalHeader .titleWrap .modalTitle {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: neutrals-dark;
}

.modalContainer .modalHeader .closeBtn {
  width: 2.75rem;
  height: 2.75rem;
  color: neutrals-dark;
}

.modalContainer .modalBody {
  padding: 0.5rem 1.5rem 1rem 1.5rem;
  color: neutrals-dark;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  height: 1px;
}

.modalContainer .modalBody.modalBodyScroll {
  overflow: hidden auto;
}

.modalContainer .modalBody .bodyLabel {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.modalContainer .modalBody .bodyDesc {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: neutrals-dark-alt;
}

.modalContainer .modalFooter {
  padding: 1rem 1.5rem 1.5rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
}

.modalContainer .modalFooter .modalBtn {
  height: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  gap: 0.25rem;
}

.redRipple {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 8px solid state-colors-error-light-alt;
  background-color: state-colors-error-light;
  display: flex;
  align-items: center;
  justify-content: center;
}

.redRipple .redTrash {
  color: state-colors-error;
}
