.docContainer {
  width: 100%;
  white-space: pre-wrap;
}

.docContainer li {
  margin-left: 1rem;
}

.docContainer img {
  max-width: 100%;
}

.docContainer h1 {
  margin: 1.25rem 0 0.75rem 0;
}

.docContainer h2,
.docContainer h3,
.docContainer h4,
.docContainer h5,
.docContainer h6 {
  margin: 0.75rem 0 0.5rem 0;
}
