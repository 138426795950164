@value slate-100 from "components/colours.module.css";
@value slate-400 from "components/colours.module.css";

.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;

  width: 50%;
  min-width: 20rem;
  max-width: 30rem;

  padding: 1rem 2rem;

  border: 1px solid slate-400;
  border-radius: 0.5rem;
  background-color: slate-100;
}
