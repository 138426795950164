.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.pdfPreview {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.filePreview {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  overflow: hidden auto;
}
