@value grey-100 from "components/colours.module.css";
@value grey-400 from "components/colours.module.css";

.empty-paragraph {
  position: relative;
  cursor: text;
}

.empty-paragraph::before {
  /* content: 'Pick a template or start typing'; */
  content: var(--emptyParagraphPlaceholder);
  pointer-events: none;
  user-select: none;
  position: absolute;
  color: grey-400;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.template {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.button {
  border: none;
  border-radius: 0.25rem;
  padding: 0.25rem 0rem;
  background-color: transparent;
  text-align: left;
  display: flex;
  align-items: center;
}
