@value blue-500 from "components/colours.module.css";
@value blue-600 from "components/colours.module.css";

@value grey-50 from "components/colours.module.css";
@value grey-100 from "components/colours.module.css";
@value grey-200 from "components/colours.module.css";
@value grey-300 from "components/colours.module.css";
@value grey-400 from "components/colours.module.css";

@value red-500 from "components/colours.module.css";
@value side-menu-width from "components/SideMenu.module.css";

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
}

.layout {
  position: relative;

  height: 100%;
  width: side-menu-width;

  border-right: 1px solid grey-100;

  display: flex;
  flex-direction: column;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 0.5rem;

  padding: 1rem;

  background-color: white;
  border-bottom: 1px solid grey-100;
}

.change-date-nav {
  display: flex;
  column-gap: 0.5rem;
}

.change-date-button {
  display: flex;
  align-items: center;

  padding: 0.5rem;

  border-radius: 0.25rem;
  background-color: grey-100;
}

.change-date-button:hover {
  background-color: grey-200;
}

.refresh-button {
  height: 25px;
  width: 30px;
  padding: 0.25rem 0.5rem;
  border-top: 1px solid grey-200;
  border-right: 1px solid grey-200;
  border-bottom: 1px solid grey-200;
  border-radius: 0 0.25rem 0.25rem 0;
}

.refresh-button:hover {
  background-color: grey-50;
}

.today-button {
  height: 25px;
  padding: 0.25rem 0.5rem;
  border: 1px solid grey-200;
  border-radius: 0.25rem 0 0 0.25rem;
}

.today-button:hover {
  background-color: grey-50;
}

.chevron {
  height: 0.6rem;
  width: 0.6rem;
  stroke-width: 2;
  stroke: grey-400;
}

.calendar-scroller {
  height: calc(100vh - 6.5rem);
  overflow-y: scroll;
}

.calendar-scroller::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.calendar-container {
  position: relative;
  /* 24 * 4 + 0.5rem adjustment to reflect the offset of the hour line */
  height: 96.5rem;
  margin: 1rem 0 1.5rem;
}

.hours {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 1rem;
}

.hour {
  display: flex;
  height: 1rem;
  padding-bottom: 4rem;
}

.hour-label {
  width: 3rem;
}

.hour-line {
  flex-grow: 1;
  margin-left: 2.8rem;
}

.event {
  position: absolute;
  left: 0;
  right: 0;

  margin: 0 1rem 0 4rem;
  padding: 0.25rem;

  text-align: left;
  overflow: hidden;
  text-decoration: none;

  border-radius: 0.25rem;

  background-color: grey-200;
}

.event:hover {
  background-color: grey-300;
}

.event:disabled {
  cursor: not-allowed;
}

.external-event {
  background-color: blue-600;
  color: white;
}

.external-event:hover {
  background-color: blue-500;
}

.selected-event {
  margin-left: 3.25rem;
  margin-right: 0.25rem;
}

.event-container {
  display: flex;
  flex-direction: column;
  row-gap: 0.1rem;

  height: 100%;

  padding-left: 0.25rem;
  border-radius: 0.25rem;
}

.selected-event .event-container {
  border-left: 4px solid white;
}

.current-time {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  /* 1px to not prevent the user from clicking on events which are below the current time */
  height: 1px;

  display: flex;
  padding: 0 1rem;
}

.current-time-circle {
  position: absolute;
  top: -0.5rem;
  height: 1rem;
  width: 1rem;
  border-radius: 0.5rem;
  background-color: red-500;
}

.current-time-line {
  flex-grow: 1;
  border-color: red-500;
}
