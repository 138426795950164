@value grey-100 from "components/colours.module.css";
@value grey-500 from "components/colours.module.css";

.form {
  margin: 0.5rem;
}

.input-container {
  border-radius: 0.3rem;
  border-color: grey-100;
}

.input {
  padding: 0 0.3rem;
  background-color: grey-100;
  border: 0px;
}
