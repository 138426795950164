@value grey-200 from "components/colours.module.css";
@value grey-300 from "components/colours.module.css";
@value grey-500 from "components/colours.module.css";

.pageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.pageContainer .pageHeader {
  border-bottom: 1px solid grey-200;
}

.pageContainer .pageContent {
  width: 100%;
  height: 100%;
  padding: 0.5rem 0.5rem 1.5rem 0.5rem;
  flex: 1;
  overflow: hidden auto;
  display: flex;
  justify-content: center;
}

.pageContainer .pageContent :global(.MuiTreeView-root) {
  max-width: 1200px;
  width: 100%;
}

.pageContainer .pageContent :global(.MuiTreeView-root) :global(.MuiTreeItem-root) {
  padding: 0.75rem 0.25rem 0.75rem 1.25rem;
}

.pageContainer .pageContent :global(.MuiTreeView-root) :global(.MuiTreeItem-root) :global(.MuiTreeItem-content) {
  background-color: transparent !important;
  padding: 0;
  flex-direction: row-reverse;
}

.pageContainer
  .pageContent
  :global(.MuiTreeView-root)
  :global(.MuiTreeItem-root)
  :global(.MuiTreeItem-content)
  :global(.MuiTreeItem-iconContainer) {
  width: auto;
  margin: 0;
}

.pageContainer
  .pageContent
  :global(.MuiTreeView-root)
  :global(.MuiTreeItem-root)
  :global(.MuiTreeItem-content)
  :global(.MuiTreeItem-label) {
  padding: 0;
  font-size: 0.875rem;
  color: grey-300;
}

.pageContainer
  .pageContent
  :global(.MuiTreeView-root)
  :global(.MuiTreeItem-root)
  :global(.MuiTreeItem-content)
  :global(.MuiTreeItem-label)
  .treeLabel {
  font-weight: 500;
  color: grey-500;
}

.pageContainer .pageContent :global(.MuiTreeView-root) :global(.MuiTreeItem-root) :global(.MuiTreeItem-group) {
  margin-left: 0;
  margin-top: 0.5rem;
  position: relative;
}

.pageContainer
  .pageContent
  :global(.MuiTreeView-root)
  :global(.MuiTreeItem-root)
  :global(.MuiTreeItem-group)
  :global(.MuiTreeItem-root) {
  margin-left: 1.5rem;
  position: relative;
}

.pageContainer
  .pageContent
  :global(.MuiTreeView-root)
  :global(.MuiTreeItem-root)
  :global(.MuiTreeItem-group)
  :global(.MuiTreeItem-root):not(:last-child):after {
  content: '';
  width: 16px;
  height: 100%;
  position: absolute;
  border: 1px solid grey-200;
  border-color: transparent transparent grey-200 grey-200;
  left: -14px;
  bottom: -19px;
}

.pageContainer
  .pageContent
  :global(.MuiTreeView-root)
  :global(.MuiTreeItem-root)
  :global(.MuiTreeItem-group)
  :global(.MuiTreeItem-root):not(.taskTreeItem) {
  padding: 0.5rem 0 0.5rem 0;
}

.pageContainer .pageContent :global(.MuiTreeView-root) :global(.MuiTreeItem-root) :global(.MuiTreeItem-group):after {
  display: none;
  content: '';
  width: 12px;
  height: 23px;
  position: absolute;
  border: 1px solid grey-200;
  border-color: transparent transparent grey-200 grey-200;
  left: 10px;
  top: -8px;
}

.pageContainer
  .pageContent
  :global(.MuiTreeView-root)
  :global(.MuiTreeItem-root):global([aria-expanded='true'])
  :global(.MuiTreeItem-group):after {
  display: block;
}

.pageContainer .pageContent :global(.MuiTreeView-root) :global(.MuiTreeItem-root).taskTreeItem {
  padding: 0;
  margin-left: 0;
  border-bottom: none;
}

.pageContainer
  .pageContent
  :global(.MuiTreeView-root)
  :global(.MuiTreeItem-root).taskTreeItem
  :global(.MuiTreeItem-content) {
  margin-bottom: 0;
}

.pageContainer .pageContent :global(.MuiTreeView-root).noGroup :global(.MuiTreeItem-root) {
  padding: 0 0.25rem 0 1.25rem !important;
}

.pageContainer .pageContent .taskTreeItem {
  position: relative;
}

.pageContainer .pageContent .taskTreeItem .accountOppoDetailRow {
  padding-left: 26px;
  margin-bottom: 4px;
}

.pageContainer .pageContent .taskTreeItem.taskTreeItemShowAccOppo::before {
  content: '';
  width: 16px;
  height: calc(100% - 40px);
  position: absolute;
  border: 1px solid grey-200;
  border-color: transparent transparent grey-200 grey-200;
  left: 10px;
  top: 28px;
}

.pageContainer .pageContent :global(.MuiTreeView-root).noGroup .taskTreeItem.taskTreeItemShowAccOppo::before {
  left: 30px;
}
