@value neutrals-white from "components/colours.module.css";

.clearFilterBtnWrap {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0.75rem;
  padding: 0.75rem 0 0 0.75rem;
  background-color: neutrals-white;
  z-index: 1;
}

.clearFilterBtnWrap .clearFilterBtn {
  font-size: 0.875rem !important;
  justify-content: flex-end !important;
  width: 100%;
}
