.document-loader {
  flex-grow: 1;

  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.document-container {
  height: 100%;
  flex-grow: 1;
}
