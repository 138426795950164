@value neutrals-white from "components/colours.module.css";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem 0;
  min-height: 100%;
}
