@value grey-200 from "components/colours.module.css";
@value grey-400 from "components/colours.module.css";
@value grey-600 from "components/colours.module.css";
@value yellow-400 from "components/colours.module.css";
.popoverWrap {
  width: 30rem;
  max-width: 100vw;
  max-height: 50vh;
  overflow: hidden auto;
}
.popoverWrap .commentGroups {
  width: 100%;
}
.popoverWrap .commentGroups .commentGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid grey-200;
}
.popoverWrap .commentGroups .commentGroup .editingComment {
  padding: 0.375rem 1rem;
}

.commentedLines {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  font-size: 0.875rem;
  color: grey-400;
  padding-left: 1rem;
  margin-left: 0.5rem;
}
.commentedLines::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 3px;
  height: 100%;
  border-radius: 2px;
  background: yellow-400;
}
.commentedLines .commentedLine {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
