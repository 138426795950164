@value grey-300 from 'components/colours.module.css';

.date {
  width: 100%;
}

.text {
  border: 1px solid blue;
}

.date-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.date-field {
  display: flex;
  align-items: center;
}

.input {
  display: none;
  opacity: 0;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  opacity: 0;
}

.date-button:hover .icon {
  opacity: 1;
}
