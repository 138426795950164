@value neutrals-white from "components/colours.module.css";
@value neutrals-dark from "components/colours.module.css";
@value grey-200 from "components/colours.module.css";
@value grey-300 from "components/colours.module.css";
@value grey-400 from "components/colours.module.css";

.taskWrap {
  width: 100%;
  display: flex;
  align-items: flex-start;
  position: relative;
  min-height: 2rem;
  border-radius: 4px;
  line-height: 1.5rem;
  font-size: 1rem;
}

.taskWrap :global(.MuiFormControlLabel-root .MuiCheckbox-root) {
  padding: 0.375rem;
}

.taskWrap :global(.MuiFormControlLabel-root .animate-spin) {
  width: 2rem;
  padding: 0.5rem;
}

.taskWrap .editor {
  color: neutrals-dark;
  cursor: text;
}

.taskWrap.done .editor {
  text-decoration: line-through;
  color: grey-400;
}

.taskWrap .checkbox {
  transform: translateX(0.175rem);
}

.taskWrap .actions {
  display: flex;
  align-items: center;
  padding-right: 0.25rem;
}

.taskWrap .actions .dueDateStr {
  color: neutrals-dark;
  font-size: 0.75rem;
  white-space: nowrap;
}

.taskWrap .actions .actionBtn {
  height: 2rem;
  min-width: 1.5rem;
  background-color: transparent;
  justify-content: center !important;
  gap: 0;
  border-radius: 3px !important;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.deletedTip {
  width: 100%;
  text-align: center;
  color: grey-300;
  padding: 0.5rem;
  border: 1px solid grey-200;
  border-radius: 0.25rem;
}
