@value neutrals-white from "components/colours.module.css";

.container {
  overflow: hidden auto;
  padding: 3.5rem 0.5rem 0.5rem 0.5rem;
  max-height: calc(100vh - 160px);
}

.searchInputWrap {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 1rem;
  padding: 0.75rem 0 0 1rem;
  background-color: neutrals-white;
  z-index: 1;
}

.searchInputWrap .searchInput {
  font-size: 0.875rem !important;
  justify-content: flex-end !important;
  width: 100%;
}
