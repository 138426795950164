@value neutrals-white from "components/colours.module.css";

.tableContainer {
  position: relative;
}

.tableContainer .tableOverlay {
  position: fixed;
  background-color: neutrals-white;
  z-index: 20000;
  border-radius: 0.25rem;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  transform: translateX(-0.75rem) translateY(-0.75rem);
  padding: 1rem 2rem;
  font-size: 14px;
  font-weight: 600;
  font-variation-settings: 'wght' 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: move;
  width: fit-content;
}

.tableContainer .tableOverlay .movingIcon {
  transform: rotate(45deg);
}
