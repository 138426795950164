@value neutrals-dark from "components/colours.module.css";
@value neutrals-white from "components/colours.module.css";
@value grey-700 from "components/colours.module.css";

.audio-recorder {
  display: flex;
  align-items: center;
  height: 2rem;
  width: 2rem;
  transition: width 0.5s ease-in-out;
  overflow: hidden;
}

.recording {
  border-radius: 1rem;
  background-color: neutrals-dark;
  color: neutrals-white;
  padding: 0 0.5rem;
  width: 232px;
}

.audio-recorder-button {
  border: none !important;
  color: neutrals-white !important;
}

.audio-recorder-button:global(.MuiButton-outlinedSecondary):hover {
  background-color: grey-700 !important;
}

.audio-recorder-large-button {
  width: 2rem !important;
  height: 2rem !important;
}

.audio-recorder-trash-button {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.audio-recorder-icon {
  width: 1rem;
  height: 1rem;
}

.audio-recorder-large-icon {
  width: 1.25rem;
  height: 1.25rem;
}

.pausing {
  color: black;
}

.audio-recorder-timer {
  width: 2.5rem;
  font-size: 0.875rem;
  white-space: nowrap;
  font-feature-settings: 'tnum';
  font-family: circular, Helvetica, sans-serif;
  text-align: center;
}

.audio-recorder-visualizer {
  margin-left: 0.25rem;
  align-self: center;
  display: flex;
  align-items: center;
  flex: 0 0 100px;
}
