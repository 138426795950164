@value neutrals-gray-alt from "components/colours.module.css";
@value neutrals-dark from "components/colours.module.css";

.title {
  margin: 0;
  font-weight: 600;
  font-size: 2rem;
  line-height: 3rem;
  letter-spacing: 0.004em;
  color: neutrals-dark;
  font-variation-settings: 'wght' 600;
}

.title:empty:not(:focus):before {
  content: attr(placeholder);
  color: neutrals-gray-alt;
  user-select: none;
  pointer-events: none;
}
