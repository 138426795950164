@value blue-600 from "components/colours.module.css";
@value green-600 from "components/colours.module.css";
@value grey-100 from "components/colours.module.css";
@value grey-200 from "components/colours.module.css";
@value grey-400 from "components/colours.module.css";
@value grey-600 from "components/colours.module.css";
@value grey-700 from "components/colours.module.css";
@value grey-500 from "components/colours.module.css";
@value orange-400 from "components/colours.module.css";
@value red-500 from "components/colours.module.css";
@value neutrals-gray from "components/colours.module.css";

.loader {
  width: 2rem;
  height: 2rem;
}

.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;

  padding: 1.25rem 1.25rem 0;
  border-bottom: 1px solid grey-100;
}

.icon-title-container {
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
}

.title-field {
  border: none;
}

.note-icon {
  height: 1rem;
  width: 1rem;
  color: grey-400;
}

.opportunity-icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: orange-400;
  color: orange-400;
}

.parent-container {
  height: 100%;
  overflow: hidden auto;
}

.container {
  display: flex;
  flex-direction: column;
  max-width: 900px;
  padding: 1rem 1rem;
  margin: auto;
}

.container .topSummary {
  margin: 0 1rem 0.8rem 4rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
  padding-bottom: 0.5rem;
  color: grey-700 !important;
}

.calendar-title {
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
}

.calendar-icon {
  height: 1rem;
  width: 1rem;
}

.calendar-data-container {
  display: grid;
  grid-template-columns: 12rem 1fr;
  row-gap: 1rem;
}

.attendees {
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.5rem;
}

.attendee {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;

  padding: 0.25rem;

  border-radius: 1rem;
  background-color: grey-100;
}

.attendee-initials {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;

  height: 1rem;
  width: 1rem;

  border-radius: 1rem;
  background-color: grey-400;
  color: white;
}

.attendee-photo {
  height: 1.25rem;
  border-radius: 1rem;
}

.attendee-status {
  height: 1.25rem;
  width: 1.25rem;
}

.attendee-status-confirmed {
  fill: green-600;
}

.attendee-status-declined {
  fill: red-500;
}

.attendee-status-maybe {
  fill: blue-600;
}

.attendee-status-pending {
  fill: grey-400;
}

.crm-data-container {
  display: grid;
  grid-template-columns: 12rem 1fr;
  row-gap: 1rem;
}

.select-selected-value {
  display: flex;
  align-items: stretch;
}

.select-selected-value .saleforce-logo-icon {
  height: 1rem;
  margin: 0.75rem;
}

.select-selected-label {
  display: flex;
  align-items: center;

  padding: 0 0.75rem;
  border-left: 1px solid grey-100;
}

.note-container {
  position: relative;
}

.note-bar {
  position: absolute;
  right: 0;
  top: 1rem;

  min-height: 2.25rem;

  display: flex;
  column-gap: 0.5rem;
  align-items: stretch;
}

.sync-button {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;

  background-color: grey-100;
  border-radius: 0.5rem;
  padding: 0.5rem 1.25rem;
}

.sync-button:hover {
  background-color: grey-200;
}

.sync-button:disabled {
  background-color: grey-200;
  cursor: not-allowed;
}

.sync-button .saleforce-logo-icon {
  height: 0.75rem;
}

.share-button {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 2.5rem;

  background-color: grey-100;
  border-radius: 0.5rem;
}

.share-button:hover {
  background-color: grey-200;
}

.share-icon {
  height: 1rem;
  color: grey-500;
}

.menu-button {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 2.5rem;

  background-color: grey-100;
  border-radius: 0.5rem;
}

.menu-button:hover {
  background-color: grey-200;
}

.menu-icon {
  height: 1rem;
  color: grey-500;
}

.save-template {
  display: flex;
  align-items: center;

  column-gap: 0.5rem;
}

.save-template-icon {
  height: 1rem;
  color: grey-500;
}

.colorNeutralsGray {
  color: neutrals-gray;
}

.bottomMargin {
  min-height: 30vh;
}
