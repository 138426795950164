@value neutrals-stroke from "components/colours.module.css";
@value neutrals-stroke-alt from "components/colours.module.css";
@value neutrals-stroke-fields from "components/colours.module.css";
@value primary-primary-1 from "components/colours.module.css";
@value neutrals-gray-alt from "components/colours.module.css";
@value neutrals-white from "components/colours.module.css";
@value neutrals-dark from "components/colours.module.css";
@value red-500 from "components/colours.module.css";
@value backgrounds-disabled from "components/colours.module.css";

.select {
  width: 100%;
  height: 2.75rem;
  min-height: 2.75rem;
  border-radius: 0.25rem;
  position: relative;
  padding-right: 0 !important;
}

.select:global(.MuiInputBase-sizeSmall) {
  height: 2.25rem;
  min-height: 2.25rem;
  border-radius: 0.25rem;
}

.select:global(.MuiInputBase-sizeSmall) .loadingSpinner {
  top: 0.4rem;
}

.select.multiline {
  height: auto;
}

.select :global(.MuiSelect-select) {
  padding-left: 0.75rem;
  font-size: 0.875rem;
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
  height: 100% !important;
  min-height: 2.25rem !important;
}

.select :global(.MuiSelect-select) :global(.hide-when-show-as-value) {
  display: none;
}

.select :global(.MuiOutlinedInput-notchedOutline) {
  border-color: neutrals-stroke-fields;
}

.select.error :global(.MuiOutlinedInput-notchedOutline) {
  border-color: red-500 !important;
}

.select:global(.Mui-disabled) {
  background-color: backgrounds-disabled;
}

.select:global(.Mui-disabled) :global(.MuiOutlinedInput-notchedOutline) {
  border-color: neutrals-stroke-fields;
}

.select:global(.Mui-disabled) .clearBtn {
  display: none;
}

.select:hover:not(:global(.Mui-disabled)) :global(.MuiOutlinedInput-notchedOutline) {
  border-color: primary-primary-1;
  border-width: 2px;
}

.select:hover:not(:global(.Mui-disabled)) .clearBtn {
  display: block;
  opacity: 1;
}

.select:global(.MuiInputBase-root.Mui-focused) :global(.MuiOutlinedInput-notchedOutline) {
  border-color: primary-primary-1;
  border-width: 2px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #eff8ff;
}

.select :global(.MuiSelect-icon) {
  position: absolute;
  width: 1rem;
  height: 1rem;
  right: 1rem;
  color: neutrals-gray-alt;
}

.select .loadingSpinner {
  position: absolute;
  top: 10px;
  right: 6px;
}

.select :global(.MuiSelect-nativeInput) {
  font-size: 0.875rem;
}

.select :global(.MuiSelect-nativeInput::placeholder) {
  color: neutrals-gray-alt;
  opacity: 1;
}

.select.noValue :global(.MuiSelect-nativeInput) {
  opacity: 1;
  top: 0;
  padding-left: 0.75rem;
  height: 100%;
}

.select .clearBtn {
  opacity: 0;
  transition: opacity 0.25s;
  display: none;
  position: absolute;
  right: 2rem;
}

.menuContent {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.menuContent .menus {
  flex: 1;
  overflow: hidden auto;
}

.menu {
  color: neutrals-dark;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-left: 0;
  font-size: 0.875rem;
  width: 100%;
}

.menu.value {
  padding-left: 0;
}

:global(.MuiPopover-root.select-field-dropdown) {
  width: 1px;
}

:global(.MuiPopover-root.select-field-dropdown) :global(.MuiPaper-root) {
  overflow: hidden;
  max-height: inherit;
  width: inherit !important;
}

:global(.MuiPopover-root.select-field-dropdown) :global(.MuiPaper-root) :global(.MuiList-root) {
  overflow: hidden auto;
  max-height: inherit;
}

:global(.MuiPopover-root.select-field-dropdown.with-input) :global(.MuiPaper-root) :global(.MuiList-root) {
  padding-top: 0;
}

:global(.MuiPopover-root.select-field-dropdown)
  :global(.MuiPaper-root)
  :global(.MuiList-root)
  :global(.MuiFormControl-root) {
  position: sticky;
  top: 0;
  left: 0;
  background-color: neutrals-white;
  z-index: 2;
  width: 100%;
  padding: 0.5rem;
}
