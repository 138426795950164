@value slate-100 from "components/colours.module.css";
@value slate-200 from "components/colours.module.css";
@value slate-300 from "components/colours.module.css";
@value blue-400 from "components/colours.module.css";
@value neutrals-white from "components/colours.module.css";

.float-icon-btn {
  background-color: slate-100;
  border: 1px solid slate-300;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.float-icon-btn:hover {
  background-color: slate-200;
}

.menu-container {
  min-width: 10rem;
  padding: 0.25rem 0;
}

.menu-container button {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  gap: 0.5rem;
  font-size: 3rem;
}

.menu-container button:hover {
  background-color: slate-100;
}

.table {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 2rem 1.5rem 0;
  margin: 0.5rem 0.5rem 0 0;
  width: fit-content;
}

.table .add-row-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1.25rem;
  width: calc(100% - 2rem);
  border-radius: 4px;
  background-color: slate-100;
}

.table .add-row-btn:hover {
  background-color: slate-200;
}

.table .add-col-btn {
  position: absolute;
  top: 0;
  right: 0.5rem;
  width: 1.25rem;
  height: calc(100% - 1.5rem);
  border-radius: 4px;
  background-color: slate-100;
}

.table .add-col-btn:hover {
  background-color: slate-200;
}

.table .table-row {
  width: fit-content;
  border-right: 1px solid slate-300;
  display: flex;
  position: relative;
}

.table .table-row .float-row-icon-btn-wrap {
  display: none;
  z-index: 14;
  position: absolute;
  left: -10px;
  top: calc(50% - 13px);
  height: 26px;
  width: 20px;
}

.table .table-row::after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  pointer-events: none;
  border-style: solid;
  border-color: blue-400;
  border-width: 2px;
  opacity: 0;
  z-index: 11;
}

.table .table-row.row-focused::after {
  opacity: 1;
}

.table .table-row:hover .float-row-icon-btn-wrap {
  display: block;
}

.table .table-row.row-focused .float-row-icon-btn-wrap {
  display: block;
}

.table .table-row:first-child .table-cell::after {
  border-top-width: 3px;
}

.table .table-row:last-child {
  border-bottom: 1px solid slate-300;
}

.table .table-row:last-child .table-cell::after {
  border-bottom-width: 3px;
}

.table .table-row .table-cell {
  position: relative;
  border-left: 1px solid slate-300;
  border-top: 1px solid slate-300;
  overflow: visible;
}

.table .table-row .table-cell .cell-width-adjust-handler {
  position: absolute;
  z-index: 13;
  top: 0;
  right: -2px;
  height: 100%;
  width: 3px;
  cursor: col-resize;
}

.table .table-row .table-cell .float-icon-btn-wrap {
  display: none;
  z-index: 14;
  position: absolute;
  top: -13px;
  left: calc(50% - 10px);
  height: 26px;
  width: 20px;
  transform: rotate(90deg);
}

.table .table-row .table-cell.dragging .cell-width-adjust-handler {
  background-color: blue-400;
}

.table .table-row .table-cell::after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  pointer-events: none;
  border-style: solid;
  border-color: blue-400;
  border-width: 0px 3px 0 3px;
  opacity: 0;
  z-index: 11;
}

.table .table-row .table-cell.col-hovered .float-icon-btn-wrap {
  display: block;
}

.table .table-row .table-cell.col-focused .float-icon-btn-wrap {
  display: block !important;
}

.table .table-row .table-cell.col-focused::after {
  opacity: 1;
}

.table .table-row .table-cell.selected:not(.col-focused)::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  pointer-events: none;
  border-style: solid;
  border-color: blue-400;
  border-width: 2px;
  z-index: 10;
}

.table:not(:hover) .add-col-btn,
.table:not(:hover) .add-row-btn {
  display: none;
}

.table:not(:hover) .table-row .table-cell .float-icon-btn-wrap {
  display: none;
}

.table:not(.table-selected) .table-row .table-cell::before {
  display: none !important;
}

.table-dialog-selected {
  outline: 2px solid blue-400;
}
