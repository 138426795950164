@value blue-200 from "components/colours.module.css";
@value blue-400 from "components/colours.module.css";

.taskContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 2rem;
  border-radius: 4px;
  padding-left: 0.5rem;
}

.taskContainer.selected {
  outline: 2px solid blue-400;
}

.taskContainer .addMoreBtn {
  width: fit-content;
  align-self: flex-end;
}

:global(.task.high-light) {
  transition: outline 300ms ease-in 0s;
  outline: 2px solid blue-400;
}

:global(.task.high-light .assigned-user-btn) {
  background-color: transparent !important;
  outline: none !important;
  box-shadow: none !important;
}
