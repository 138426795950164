@value blue-500 from "components/colours.module.css";

.baseCheckbox:global(.MuiFormControlLabel-root) {
  margin-right: 0;
}

.baseCheckbox:global(.MuiFormControlLabel-root) .spinner {
  width: 2.375rem;
  padding: 0.5rem;
}

.baseCheckbox:global(.MuiFormControlLabel-root) :global(.MuiFormControlLabel-label) {
  font-size: inherit;
  color: inherit;
}

.circle {
  border-radius: 50%;
  border: 1.5px solid blue-500;
  transform: scale(0.8);
}
