@value grey-100 from "components/colours.module.css";
@value grey-200 from "components/colours.module.css";
@value side-menu-width from "components/SideMenu.module.css";

.sidebar {
  width: side-menu-width;
  height: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  border-right: 1px solid grey-200;
  background-color: white;
  /* transition: margin 0.15s; */
}

.sidebar-hidden {
  position: fixed;
  margin-left: -17.5rem;
  z-index: 0;
}

.sidebar-hover {
  position: fixed;
  margin-left: 3.5rem; /* width of navbar */
  border-right: 1px solid grey-200;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 20px 20px 2px;
  z-index: 4;
}

.sidebar-fixed {
  position: relative;
  transition: none;
  margin-left: 0;
  z-index: 1;
}
