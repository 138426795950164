@value grey-100 from "components/colours.module.css";
@value grey-200 from "components/colours.module.css";
@value grey-400 from "components/colours.module.css";
@value orange-400 from "components/colours.module.css";

.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;

  padding: 1.25rem 1.25rem 0;
  border-bottom: 1px solid grey-100;
}

.header-title {
  display: flex;
  column-gap: 1rem;
  align-items: center;
}

.icon-title-container {
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
}

.note-icon {
  height: 1rem;
  width: 1rem;
  color: grey-400;
}

.opportunity-icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: orange-400;
  color: orange-400;
}

.container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  padding: 1.5rem 1rem;
}

.note-container {
  position: relative;
}

.note-content {
  display: block;

  padding: 1rem;

  border: 1px solid grey-100;
  border-radius: 0.5rem;
}

.note-content ul,
.note-content ol {
  margin-left: 1rem;
}

.note-bar {
  position: absolute;
  right: 1rem;
  top: 1rem;

  min-height: 2.25rem;

  display: flex;
  column-gap: 0.5rem;
  align-items: center;
}

.sync-button {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;

  background-color: grey-100;
  border-radius: 0.5rem;
  padding: 0.5rem 1.25rem;
}

.sync-button:disabled {
  background-color: grey-200;
  cursor: not-allowed;
}

.sync-button .saleforce-logo-icon {
  height: 0.75rem;
}
